import * as React from 'react';
import Typography from '@mui/material/Typography';
import Title from './Title';

interface FinanceProps {
  title: string;
  dateInfo: string;
  sum: string;
  status?: string
}

const Deposits = ({ title, dateInfo, sum, status }: FinanceProps) => {
  return (
    <React.Fragment>
      <Title status={status}>{title}</Title>
      <Typography component="p" variant="h4" sx={{ marginTop: 1 }}>
        {`R$ ${sum}`}
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1, marginTop: 1 }}>
        {`Última atualização: ${dateInfo}`}
      </Typography>
    </React.Fragment>
  );
}

export default Deposits;
