import * as React from "react";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import MainView from "../MainView";
import { convertNormalDate, convertToLibraryDate, handleCEP, handleCPF, validateCNPJ, validateCPF, validateEmail } from "../../utils/helpers";
import { updateCustomer } from "../../reduxState/customer/customerSlice";
import { Customer, AddressDetails, IAlerts } from "../../utils/types";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../reduxState/store";
import { Alerts } from "../../components/Alerts";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate, useLocation } from "react-router-dom";
import Container from "@mui/material/Container";
import dayjs from 'dayjs';
import { Stack, Button } from "@mui/material";
import CPFInput from '../../components/CPFInput';
import PhoneInput from '../../components/PhoneInput';
import CNPJInput from '../../components/CNPJInput';
import Alert from "@mui/material/Alert";
import { days, period, address, servicesType } from "../../utils/commons";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const EditCustomer = () => {
  const location = useLocation();
  const customer = location.state;
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { auth } = useSelector((state: any) => state.auth)
  
  const [name, setName] = React.useState<string>(customer.name);
  const [email, setEmail] = React.useState<string>(customer.email);
  const [myCep, setMyCep] = React.useState<string>(customer.address.cep);
  const [myPrice, setMyPrice] = React.useState<string>(customer.price);
  const [serviceDays, setServiceDays] = React.useState<string[]>(customer.serviceDays);
  const [periodOfTime, setPeriodOfTime] = React.useState<string[]>(customer.periodOfTime);
  const [payment, setPayment] = React.useState<string>(customer.paymentMethod);
  const [serviceType, setServiceType] = React.useState<string[]>(customer.services);
  const [client, setVisitClient] = React.useState<string>(customer.customerType);
  const [paymentOft, setPaymentOft] = React.useState<string>(customer.customerType);
  const [paymentStatus, setPaymentStatus] = React.useState<string>(customer.paymentStatus);
  const [myAddress, setMyAddress] = React.useState<AddressDetails>(customer.address);
  const [showAlert, setShowAlert] = React.useState<IAlerts>({status: undefined, message: '', open: false});
  // const [isValidCNPJ, setIsValidCNPJ] = React.useState<boolean>(false);
  const [mygender, setMyGender] = React.useState<string>(customer.gender);
  const [mycpf, setMyCPF] = React.useState<string>(customer.cpf);
  const [mycnpj, setMyCNPJ] = React.useState<string>(customer.cnpj);
  const [myphone, setMyPhone] = React.useState<string>(customer.phone);
  // const [isValidCPF, setIsValidCPF] = React.useState<boolean>(false);
  const [isValidEmail, setIsValidEmail] = React.useState<boolean>(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const payload: Customer = {
      name: formData.get('name') as string,
      cpf: handleCPF(formData.get('cpf')) as string,
      cnpj: mycnpj || null,
      email: formData.get('email') as string,
      phone: formData.get('phone') as string,
      address: {
        cep: myAddress.cep,
        street: myAddress.street,
        city: myAddress.city,
        complement: formData.get('complement') as string,
        number: formData.get('number') as string,
        state: myAddress.state,
        district: myAddress.district,
      },
      gender: formData.get('gender') as string,
      datePaymentExpired: formData.get('expired') as string,
      paymentMethod: payment,
      price: formData.get('price') as string,
      services: serviceType,
      customerType: client,
      paymentOften: paymentOft,
      paymentStatus,
      serviceDays,
      periodOfTime,
      realDatePaymentExpired: convertNormalDate(formData.get('expired')),
      lastUpdate: Date.now(),
    }

    updateCustomer(payload, customer._id, auth.accessToken, dispatch).then((response) => {
      setShowAlert({
        status: 'success',
        message: 'Cliente atualizado com sucesso',
        open: true
      })
    }).catch((err: any) => {
      setShowAlert({
        status: 'error',
        message: 'Ocorreu um problema ao atualizar o cliente, revise os dados e tente novamente',
        open: true
      })
    })
  };

  return (
    <MainView>
      <Container maxWidth="lg">
        {showAlert.open &&
          <Alerts
            displayAlert={setShowAlert}
            status={showAlert.status}
            message={showAlert.message}
          />
        }
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Atualizar Cliente
          </Typography>
          <Box
            id="myform"
            component="form"
            style={{ width: "100%" }}
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="name"
                  required
                  fullWidth
                  id="name"
                  label="Nome"
                  autoFocus
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onBlur={(e) => setIsValidEmail(!validateEmail(e.target.value))}
                />
              </Grid>
              {customer.cnpj &&
                <Grid item xs={12} sm={6}>
                  <InputLabel id="documento-cliente">CNPJ</InputLabel>
                  <CNPJInput value={mycnpj} customInput={TextField}
                    // onBlur={(e: any) => setIsValidCNPJ(!validateCNPJ(e.target.value))}
                    onChange={(e: any) => setMyCNPJ(e.target.value)}
                  />
                  {/* {isValidCNPJ &&
                    <Alert style={{ marginTop: '10px' }} variant="outlined" severity="error">
                      O CNPJ é inválido.
                    </Alert>
                  } */}
                </Grid>
              }
              {customer.cpf &&
                <Grid item xs={12} sm={6}>
                  <InputLabel id="meu-cpf">CPF*</InputLabel>
                  <CPFInput value={mycpf} customInput={TextField}
                    // onBlur={(e: any) => setIsValidCPF(!validateCPF(e.target.value))}
                    onChange={(e: any) => setMyCPF(e.target.value)}
                  />
                  {/* {isValidCPF &&
                    <Alert style={{ marginTop: '10px' }} variant="outlined" severity="error">
                      O CPF digitado é inválido.
                    </Alert>
                  } */}
                </Grid>
              }
              <Grid item xs={12} sm={6}>
              <InputLabel id="meu-celular">Celular*</InputLabel>
                <PhoneInput value={myphone} onChange={(e: any) => setMyPhone(e.target.value)} customInput={TextField} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  required
                  fullWidth
                  name="cep"
                  label="CEP"
                  id="cep"
                  autoComplete="cep"
                  onChange={(e) => {
                    if (myCep.length < 8) {
                      setMyAddress(address)
                    }
                    setMyCep(e.target.value.replace('-', ''))
                    handleCEP(e.target.value.replace('-', ''), setMyAddress)
                  }}
                  value={myCep}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  required
                  fullWidth
                  name="street"
                  label="Rua, Avenida..."
                  id="street"
                  autoComplete="street"
                  disabled
                  value={myAddress.street}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  required
                  fullWidth
                  name="city"
                  label="Cidade"
                  id="city"
                  autoComplete="city"
                  disabled
                  value={myAddress.city}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  required
                  fullWidth
                  name="number"
                  label="Número"
                  id="number"
                  autoComplete="number"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  required
                  fullWidth
                  name="complement"
                  label="Complemento"
                  id="complement"
                  autoComplete="complement"
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  required
                  fullWidth
                  name="state"
                  label="UF"
                  id="state"
                  autoComplete="state"
                  disabled
                  value={myAddress.state}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  required
                  fullWidth
                  name="district"
                  label="Bairo"
                  id="district"
                  autoComplete="district"
                  disabled
                  value={myAddress.district}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    value={dayjs(convertToLibraryDate(customer.datePaymentExpired))}
                    slotProps={{ textField: { fullWidth: true } }}
                    name="expired"
                    className='calendar'
                    label="Data de vencimento"
                  />
                </LocalizationProvider>
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  name="price"
                  label="Valor a ser pago"
                  id="price"
                  autoComplete="price"
                  value={myPrice}
                  onChange={(e) => setMyPrice(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
              <InputLabel id="metodo-de-pagamento">Método de Pagamento</InputLabel>
                <Select
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Método de pagamento"
                  value={payment}
                  onChange={(e) => setPayment(e.target.value)}
                >
                  <MenuItem value='credit card'>Cartão de Crédito</MenuItem>
                  <MenuItem value='pix'>Pix</MenuItem>
                  <MenuItem value='boleto'>Boleto</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel id="tipo-de-servico">Tipo de serviço</InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={serviceType}
                    onChange={(e) => setServiceType(e.target.value as string[])}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                    fullWidth
                  >
                    {servicesType.map((service) => (
                      <MenuItem key={service} value={service}>
                        <Checkbox checked={serviceType.indexOf(service) > -1} />
                        <ListItemText primary={service} />
                      </MenuItem>
                    ))}
                  </Select>
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel id="tipo-de-cliente">Frequência de visitas</InputLabel>
                <Select
                  fullWidth
                  labelId="demo-simple-select-label-customer"
                  id="demo-simple-select-customer"
                  label="Tipo de cliente"
                  value={client}
                  onChange={(e ) => setVisitClient(e.target.value)}
                >
                  <MenuItem value='semanal'>Semanal</MenuItem>
                  <MenuItem value='quinzenal'>Quinzenal</MenuItem>
                  <MenuItem value='mensal'>Mensal</MenuItem>
                  <MenuItem value='livre'>Livre</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel id="frequencia-de-cobranca">Frequência de cobrança</InputLabel>
                <Select
                  fullWidth
                  labelId="demo-simple-select-label-customer"
                  id="demo-simple-select-customer"
                  label="Frequência de cobrança"
                  value={paymentOft}
                  onChange={(e ) => setPaymentOft(e.target.value)}
                >
                  <MenuItem value='semanal'>Semanal</MenuItem>
                  <MenuItem value='quinzenal'>Quinzenal</MenuItem>
                  <MenuItem value='mensal'>Mensal</MenuItem>
                  <MenuItem value='livre'>Livre</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel id="metodo-de-pagamento">Status de Pagamento</InputLabel>
                <Select
                  fullWidth
                  labelId="demo-simple-select-label-payment-status"
                  id="demo-simple-select-payment-status"
                  label="Status de pagamento"
                  value={paymentStatus}
                  onChange={(e ) => setPaymentStatus(e.target.value)}
                >
                  <MenuItem value='pago'>Pago</MenuItem>
                  <MenuItem value='pendente'>Pendente</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel id="metodo-de-pagamento">Dias dos serviços</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={serviceDays}
                  onChange={(e) => setServiceDays(e.target.value as string[])}
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                  fullWidth
                >
                  {days.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={serviceDays.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel id="periodo-de-atendimento">Periodo de atendimento</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={periodOfTime}
                  onChange={(e) => setPeriodOfTime(e.target.value as string[])}
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                  fullWidth
                >
                  {period.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={periodOfTime.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel id="metodo-de-pagamento">Gênero</InputLabel>
                <RadioGroup
                  row
                  name="paymentMethod"
                  defaultValue={mygender}
                  onChange={(e) => setMyGender(e.target.value)}
                >
                  <FormControlLabel name='gender' value="female" control={<Radio />} label="Feminino" />
                  <FormControlLabel name='gender' value="male" control={<Radio />} label="Masculino" />
                </RadioGroup>
              </Grid>
            </Grid>
            <Stack style={{ marginTop: 30, marginBottom: 15 }} direction="row" spacing={2}>
              <Button
                fullWidth
                variant="outlined"
                onClick={() => navigate("/clientes")}
              >
                Voltar
              </Button>
              <LoadingButton
                fullWidth
                sx={{ mt: 3, mb: 2 }}
                type='submit'
                variant="contained"
                disabled={/*isValidCNPJ || isValidCPF ||*/ isValidEmail || name === '' || mygender === '' || /*mycnpj === '' || handleCPF(mycpf) === '' ||*/ email === '' || myphone === '' || myPrice === '' || payment === '' || client === '' || paymentOft === '' || paymentStatus === '' || serviceDays.length === 0 || periodOfTime.length === 0}
              >
                <span>Atualizar Cliente</span>
              </LoadingButton>
            </Stack>
          </Box>
        </Box>
      </Container>
    </MainView>
  );
};

export default EditCustomer;
