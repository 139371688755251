import * as React from 'react';
import Typography from '@mui/material/Typography';

interface TitleProps {
  children?: React.ReactNode;
  status?: string;
}

export default function Title(props: TitleProps) {
  return (
    <Typography style={props.status === 'pendente' ? { color: 'orange' } : {}} component="h2" variant="h6" color="primary" gutterBottom>
      {props.children}
    </Typography>
  );
}