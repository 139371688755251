import { useState } from 'react';
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { authLogin } from '../../reduxState/auth/authSlice';
import { AppDispatch } from '../../reduxState/store';
import Alert from "@mui/material/Alert";
import Link from "@mui/material/Link";
import '../../App.css';

const Login = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { auth } = useSelector((state: any) => state.auth)
  const isAuthorized = auth && auth.accessToken && auth.accessToken !== '';

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [hasError, setHasError] = useState(false);

  const handleLogin = () => {
    const payload = { email, password };
    dispatch(authLogin(payload)).then((resp) => {
      if (resp.type === "auth/authLogin/rejected") {
        setHasError(true);
      } else {
        setHasError(false);
      }
      
    });
  };

  if (isAuthorized) {
    return <Navigate to="/clientes" />
  }

  return (
    <div className="App">
      <div className="login-container">
        <div className='logo'>
          <img alt='piscineiros.com' src='./assets/logo.png' width={320} />
        </div>
        <form>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label>Senha:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="button" onClick={handleLogin}>
            Entrar
          </button>
          <Link style={{ fontSize: 15, marginTop: 6 }} href="piscineiro/adicionar?cadastro=1&code=Pi4gTY36">
            Registre-se como um Piscineiro.
          </Link>
        </form>
        {hasError &&
          <Alert style={{ marginTop: '10px' }} variant="outlined" severity="error">
            E-mail ou Senha incorreto.
          </Alert>
        }
      </div>
    </div>
  );
}

export default Login;
