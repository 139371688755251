import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { Provider } from 'utils/types';
import { setEndpoint } from 'utils/environment';

type ProviderState = {
  provider: Provider|null;
  status: string|null;
  error?: string|undefined;
}

const initialState: ProviderState = {
  provider: null,
  status: null,
};

export const loadProdivers = (accessToken: string, providerId: string, dispatch: any) => {
  const URL = setEndpoint().endpoint_base;
  const headers = { 'Authorization': `Bearer ${accessToken}` };
  
  return axios.get(`${URL}/providers/${providerId}`, { headers })
    .then(({ data }) => dispatch(setProviderOnState(data)))
}

export const addProvider = createAsyncThunk(
  'provider/addProvider',
  async (bodyParse: Provider) => {
    const URL = setEndpoint().endpoint_base;
    const response = await axios.post(`${URL}/providers`, bodyParse);
    return response.data;
  }
)

const ProviderSlice = createSlice({
  name: 'provider',
  initialState,
  reducers: {
    resetProvider: () => initialState,
    setProviderOnState: (state: ProviderState, action: any) => {
      state.provider = action.payload;
      state.status = 'success';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addProvider.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addProvider.fulfilled, (state, action) => {
        state.provider = action.payload;
        state.status = 'success';
      })
      .addCase(addProvider.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { resetProvider, setProviderOnState } = ProviderSlice.actions;
export default ProviderSlice.reducer;
