import { PatternFormat } from "react-number-format";

const CNPJInput = ({ value, customInput, onChange, onBlur }: { value?: any, customInput: any, onChange?: any, onBlur?: any }) => {
  return (
    <PatternFormat
      name="cnpj"
      id="cnpj"
      style={{ width: "100%" }}
      customInput={customInput}
      format="##.###.###/####-##"
      allowEmptyFormatting
      mask="_"
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

export default CNPJInput;
