import { Stack, Button, Container, Typography, Grid } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { Customer } from "../../utils/types";
import MainView from "../MainView";

const ProfilePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentCustomer: Customer = location.state;

  return (
    <MainView>
      <Container style={{ marginTop: 30 }} maxWidth="md">
        <Typography variant="h4" gutterBottom>
          Perfil do Cliente
        </Typography>
        <Grid style={{ marginTop: 10 }} container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="body1">
              <strong>Nome:</strong> {currentCustomer.name}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              <strong>{`${currentCustomer.cpf ? 'CPF' : 'CNPJ'}`}</strong> {currentCustomer.cpf || currentCustomer.cnpj}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              <strong>E-mail:</strong> {currentCustomer.email}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              <strong>Telefone:</strong> {currentCustomer.phone}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              <strong>CEP:</strong> {currentCustomer.address.cep}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              <strong>Rua:</strong> {currentCustomer.address.street}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              <strong>Cidade:</strong> {currentCustomer.address.city}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              <strong>Complemento:</strong> {currentCustomer.address.complement}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              <strong>Estado:</strong> {currentCustomer.address.state}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              <strong>Bairro:</strong> {currentCustomer.address.district}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              <strong>Gênero:</strong> {currentCustomer.gender}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              <strong>Data de Vencimento:</strong> {currentCustomer.datePaymentExpired}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              <strong>Método de Pagamento:</strong> {currentCustomer.paymentMethod}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              <strong>Preço:</strong> {currentCustomer.price}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              <strong>Serviços:</strong> {currentCustomer.services}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              <strong>Tipo de Cliente:</strong> {currentCustomer.customerType}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              <strong>Status do Pagamento:</strong> {currentCustomer.paymentStatus}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              <strong>Dias de Serviço:</strong> {currentCustomer.serviceDays}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              <strong>Período de atendimento:</strong> {currentCustomer.periodOfTime}
            </Typography>
          </Grid>
        </Grid>
        <Stack style={{ marginTop: 30 }} direction="row" spacing={2}>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => navigate("/clientes")}
          >
            Voltar
          </Button>
          <Button
            fullWidth
            variant="contained"
            onClick={() =>
              navigate(`/clientes/editar/${currentCustomer._id}`, { state: currentCustomer })
            }
          >
            Editar
          </Button>
        </Stack>
      </Container>
    </MainView>
  );
};

export default ProfilePage;
