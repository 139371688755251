import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PeopleIcon from '@mui/icons-material/People';
import Help from '@mui/icons-material/Help';
import { useNavigate } from "react-router-dom";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

type TMenuItems = {
  name: string;
  icon: any;
  route?: string;
}

const MenuItems = () => {
  const navigate = useNavigate();
  const options: TMenuItems[] = [
    {
      name: 'Clientes',
      route: '/clientes',
      icon: <PeopleIcon />
    },
    {
      name: 'Financeiro',
      route: '/financeiro',
      icon: <AttachMoneyIcon />
    },
    {
      name: 'Suporte',
      route: '/suporte',
      icon: <Help />
    },
  ];

  return (
    <>
      {options.map((option: any, key: number) => (
        <ListItemButton key={key} onClick={() => navigate(option.route)}>
          <ListItemIcon>
            {option.icon}
          </ListItemIcon>
          <ListItemText primary={option.name} />
        </ListItemButton>
      ))}
    </>
  )
}

const MainMenu = () => (
  <React.Fragment>
    <MenuItems />
  </React.Fragment>
);

export default MainMenu;
