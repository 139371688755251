import React from 'react';
import { Avatar, Typography, Container, Paper, Box } from '@mui/material';
import MainView from '../MainView';
import { useSelector } from 'react-redux';

const UserProfile: React.FC<any> = () => {
  const { provider } = useSelector((state: any) => state.provider);

  return (
    <MainView>
        <Container maxWidth="sm">
        <Paper elevation={3} style={{ display: 'block', padding: '20px', marginTop: '20px' }}>
            <Box style={{ float: 'left', marginRight: 20 }}>
                <Avatar alt={provider.name} src={''} sx={{ width: 100, height: 100, marginBottom: '10px' }} />
            </Box>
            <Box>
                <Typography variant="h5" component="div" gutterBottom>
                {provider.name}
                </Typography>
                <Typography variant="body1" gutterBottom>
                Email: {provider.email}
                </Typography>
                <Typography variant="body1" gutterBottom>
                Perfil: {provider.profiles[0]}
                </Typography>
            </Box>
        </Paper>
        {/* <Box
            display="flex"
            justifyContent="flex-end"
        >
            <Button
            color="inherit"
            style={{ marginBottom: 5, cursor: 'pointer'}}
            onClick={() => alert('adicionar rota')}
            >
            Editar Perfil
            </Button>
        </Box> */}
        </Container>
    </MainView>
  );
};

export default UserProfile;
