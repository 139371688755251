import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { Customer } from 'utils/types';
import { setEndpoint } from 'utils/environment';

type CustomerState = {
  customers: any[];
  status: string|null;
  error?: string|undefined|any[];
}

const initialState: CustomerState = {
  customers: [] as Customer[],
  status: null,
};

export const loadCustomers = (accessToken: string, providerId: string, dispatch: any) => {
  const URL = setEndpoint().endpoint_base;
  const headers = { 'Authorization': `Bearer ${accessToken}` };
  return axios.get(`${URL}/customers/${providerId}`, { headers })
    .then(({ data }) => dispatch(setCustomerOnState(data)))
}

export const updateCustomer = (body: any, customerId: string, accessToken: string, dispatch: any) => {
  const URL = setEndpoint().endpoint_base;
  const headers = { 'Authorization': `Bearer ${accessToken}` };
  
  return axios.put(`${URL}/customers/${customerId}`, body, { headers })
    .then(({ data, status }) => {
      if (data._id && status === 200) {
        return dispatch(updateCustomerOnState(data))
      }
    });
}

export const deleteCustomer = (customerId: string, accessToken: string, dispatch: any) => {
  const URL = setEndpoint().endpoint_base;
  const headers = { 'Authorization': `Bearer ${accessToken}` };
  
  return axios.delete(`${URL}/customers/${customerId}`, { headers }).then((response) => {
    return dispatch(deleteCustomerOnState(customerId as any))
  });
}

export const addCustomer = createAsyncThunk(
  'customer/addCustomer',
  async (bodyParse: Customer, { getState }) => {
    const state: any = getState();
    const URL = setEndpoint().endpoint_base;
    const headers = { 'Authorization': `Bearer ${state.auth.auth.accessToken}` };
    const response = await axios.post(`${URL}/customers`, bodyParse, { headers });
    return response.data;
  }
)

const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    resetCustomer: () => initialState,
    setCustomerError: (state: CustomerState, action: any) => {
      state.customers = initialState.customers
      state.status = `failed ${action.payload.status}`;
      state.error = action.payload.data.errors
    },
    setCustomerOnState: (state: CustomerState, action: any) => {
      state.customers = action.payload;
      state.status = action.payload.length > 0 ? 'success' : 'failed';
    },
    updateCustomerOnState: (state: CustomerState, action: any) => {
      state.status = 'success';
      state.customers.filter((customer: Customer, index: any) => {
        if (customer._id === action.payload._id) {
          return state.customers[index] = action.payload;
        }
        return state.customers;
      })
    },
    deleteCustomerOnState: (state: CustomerState, action: any) => {
      state.customers = state.customers.filter(customer => customer._id !== action.payload)
      state.status = 'deleted';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addCustomer.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addCustomer.fulfilled, (state, action) => {
        state.customers = [...state.customers, action.payload];
        state.status = 'added';
      })
      .addCase(addCustomer.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const {
  setCustomerError,
  resetCustomer,
  setCustomerOnState,
  updateCustomerOnState,
  deleteCustomerOnState
} = customerSlice.actions;

export default customerSlice.reducer;
