import { configureStore, combineReducers } from '@reduxjs/toolkit';
import authReducer from 'reduxState/auth/authSlice';
import customerReducer from 'reduxState/customer/customerSlice';
import providerSlice from 'reduxState/provider/providerSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

const persistConfig = {
  key: 'root',
  storage,
}

const rootReducer = combineReducers({ 
  auth: authReducer,
  customer: customerReducer,
  provider: providerSlice
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store)

export type RootStore = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch
