import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './reduxState/store';
import App from './App';
import './index.css';

const container: any = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <React.StrictMode>
    <PersistGate loading={null} persistor={persistor}>
      <App />
      </PersistGate>
    </React.StrictMode>
  </Provider>
);

reportWebVitals();
