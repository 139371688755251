import React from 'react'
import { NumericFormat } from 'react-number-format';

const CurrencyInput = ({ customInput, onChange }: { customInput: any, onChange?: any }) => {
    return (
        <NumericFormat
            required
            name="price"
            customInput={customInput}
            decimalScale={2}
            decimalSeparator=","
            prefix="R$ "
            fixedDecimalScale
            style={{ width: '100%' }}
            placeholder="Valor de R$ 0,00"
            onChange={onChange}
        />
    )
}

export default CurrencyInput;
