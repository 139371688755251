import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { setEndpoint } from 'utils/environment';

type StateProvider = {
  name: string|null;
  email: string|null;
  accessToken: string|null;
}

type AuthState = {
  auth: StateProvider | null;
  status: string|null;
  error?: string|undefined;
}

const initialState: AuthState = {
  auth: null,
  status: null,
};

export const authLogin = createAsyncThunk(
  'auth/authLogin',
  async (body: { email: string, password: string }) => {
    const URL = setEndpoint().endpoint_base;
    const response = await axios.post(`${URL}/providers/authenticate`, body);
    return response.data;
  }
)

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(authLogin.pending, (state) => {
        state.status = "loading";
      })
      .addCase(authLogin.fulfilled, (state, action) => {
        state.auth = action.payload
        state.status = "success";
      })
      .addCase(authLogin.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
