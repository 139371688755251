import * as React from "react";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import Box from "@mui/material/Box";
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Checkbox from '@mui/material/Checkbox';
import { handleCPF, validateCPF, handlePhone } from "../../utils/helpers";
import { Provider, IAlerts } from "../../utils/types";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { AppDispatch } from "../../reduxState/store";
import { Alerts } from "../../components/Alerts";
import LoadingButton from "@mui/lab/LoadingButton";
import MenuItem from '@mui/material/MenuItem';
import { Stack, Button } from "@mui/material";
import CPFInput from "../../components/CPFInput";
import CNPJInput from "../../components/CNPJInput";
import PhoneInput from "../../components/PhoneInput";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import { addProvider } from 'reduxState/provider/providerSlice';
import ListItemText from '@mui/material/ListItemText';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from "@mui/material/Link";
import { servicesType } from "../../utils/commons";

const CODE: string = 'Pi4gTY36';
const CAD: string = '1';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddNewProvider = () => {
  const [showAlert, setShowAlert] = React.useState<IAlerts>({status: undefined, message: '', open: false});
  const [mypassword, setMyPassword] = React.useState<any>('');
  const [showPasswordError, setShowPasswordError] = React.useState<boolean>(false);
  const [serviceType, setServiceType] = React.useState<string[]>([]);
  const [isNotValidCPF, setIsNotValidCPF] = React.useState<boolean>(false);
  const [isValidPhone, setIsValidPhone] = React.useState<boolean>(false);
  const [mycpf, setMyCPF] = React.useState<string>('');
  const [myphone, setMyPhone] = React.useState<string>('');
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const defaultTheme = createTheme();

  const handlePasswordValidation = (e: any) => {
    if (mypassword === e.target.value && e.target.value >= 6 && mypassword >= 6) {
      setShowPasswordError(false);
    }
  }

  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const payload: Provider = {
      name: formData.get("name") as string,
      cpf: handleCPF(formData.get("cpf")) as string,
      email: formData.get("email") as string,
      phone: formData.get("phone") as string,
      cnpj: formData.get("cnpj") !== '__.___.___/____-__' ? formData.get("cnpj") : '' as any,
      companyName: formData.get("companyName") as string,
      password: formData.get("password") as string,
      services: serviceType,
      gender: formData.get('gender') as string,
      repeatPassword: formData.get("repeatPassword") as string,
      profiles: ['provider'],
    };

    if ((payload.password !== payload.repeatPassword) || payload.password.length < 6) {
      setShowPasswordError(true);
      return null;
    }

    await dispatch(addProvider(payload)).then((response) => {
      if (response.meta.requestStatus === 'fulfilled') {
        setShowAlert({
          status: 'success',
          message: 'Cadastro feito com sucesso',
          open: true
        });

        (document as any).getElementById("myform").reset();
        setServiceType([]);
      } else {
        setShowAlert({
          status: 'error',
          message: 'Error ao realizar o cadastro, tente novamente!',
          open: true
        })
      }
    });
  };
  
  // Validar queryparam para cadastrar piscineiro
  if ((query.get("cadastro") !== CAD) || (query.get("code") !== CODE)) {
    return (
      <p style={{ textAlign: 'center', marginTop: '100px' }}>
        <Typography component="h1" variant="h5">
          Página não encontrada - 404 :(
        </Typography>
      </p>
    )
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 5,
          }}
        >
          {showAlert.open &&
            <Alerts
              displayAlert={setShowAlert}
              status={showAlert.status}
              message={showAlert.message}
              style={{ width: '100%' }}
            />
          }
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Cadastrar Piscineiros
          </Typography>
          <Box
            id="myform"
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="name"
                  name="name"
                  required
                  fullWidth
                  id="name"
                  label="Nome Completo"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel id="cpf-provider">CPF*</InputLabel>
                <CPFInput
                  customInput={TextField}
                  onChange={(e: any) => { setMyCPF(e.target.value)} }
                  onBlur={(e: any) => setIsNotValidCPF(!validateCPF(e.target.value) || handleCPF(mycpf).length < 11)}
                />
                  {isNotValidCPF &&
                    <Alert style={{ marginTop: '10px' }} variant="outlined" severity="error">
                      O CPF digitado é inválido.
                    </Alert>
                  }
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel id="phone-provider">Celular*</InputLabel>
                <PhoneInput
                  customInput={TextField}
                  onChange={(e: any) => setMyPhone(e.target.value)}
                  onBlur={(e: any) => {
                    setIsValidPhone(e.target.value === '(__) 9____-____' ? true : false)
                  }}
                />
                {isValidPhone &&
                    <Alert style={{ marginTop: '10px' }} variant="outlined" severity="error">
                      O Telefone digitado é inválido.
                    </Alert>
                  }
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputLabel id="cnpj-provider">CNPJ</InputLabel>
                <CNPJInput customInput={TextField} />
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputLabel id="tipo-de-servico">Tipo de serviço a oferecer</InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox-services"
                    multiple
                    value={serviceType}
                    onChange={(e) => setServiceType(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value)}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                    fullWidth
                  >
                    {servicesType.map((service) => (
                      <MenuItem key={service} value={service}>
                        <Checkbox checked={serviceType.indexOf(service) > -1} />
                        <ListItemText primary={service} />
                      </MenuItem>
                    ))}
                  </Select>
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  autoComplete="companyName"
                  name="companyName"
                  fullWidth
                  id="razaoSocial"
                  label="Razao Social"
                  autoFocus
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="password"
                  name="password"
                  required
                  fullWidth
                  id="password"
                  label="Senha"
                  autoFocus
                  type="password"
                  onChange={(e: any) => setMyPassword(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="repeatPassword"
                  name="repeatPassword"
                  required
                  fullWidth
                  id="repeatPassword"
                  label="Repetir Senha"
                  autoFocus
                  type="password"
                  onChange={handlePasswordValidation}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel id="genero">Gênero</InputLabel>
                <RadioGroup
                  row
                  name="genero"
                >
                  <FormControlLabel name='gender' value="female" control={<Radio />} label="Feminino" />
                  <FormControlLabel name='gender' value="male" control={<Radio />} label="Masculino" />
                </RadioGroup>
              </Grid>
              {showPasswordError &&
                <Alert style={{ marginLeft: '15px', marginTop: '10px', width: '100%' }} variant="outlined" severity="error">
                  As Senhas digitadas não são iguais ou tem menos de 6 caracteres.
                </Alert>
              }
            </Grid>
            <Stack style={{ marginTop: 10 }} spacing={2}>
              {!showAlert.status &&
                <LoadingButton
                  fullWidth
                  sx={{ mt: 3, mb: 2 }}
                  type="submit"
                  variant="contained"
                  disabled={!isNotValidCPF && !isValidPhone && handlePhone(myphone).length < 11}
                >
                  <span>Cadastrar</span>
                </LoadingButton>
              }
              {showAlert.status === 'success' &&
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => navigate("/login")}
                >
                  acessar sua conta agora
                </Button>
              }
            </Stack>
            {showAlert.status !== 'success' &&
              <Link style={{ fontSize: 15, marginTop: 5 }} href="login">
                Voltar para página de login
              </Link>
            }
            <p style={{ fontSize: 12 }}>(*) campos obrigatórios</p>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default AddNewProvider;
