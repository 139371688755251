import { AddressDetails } from './types';

export const servicesType = [
  'Tratamento de Piscinas',
  'Troca de Areia',
  'Manutenção de Filtros',
  'Manutenção de Bombas',
  'Reforma de Piscina de Fibra',
  'Reforma de Piscina de Alvenaria',
  'Colocação de Vinil ',
  'Colocação de Tela Armada',
  'Construção de Piscinas',
  'Conserto de Vazamentos',
  'Instalação/Manutenção Hidráulica ',
  'Instalação/Manutenção Iluminação ',
  'Instalação/Manutenção de Clorador',
  'Instalação/Manutenção de Aquecimento',
];

export const days = [
  'Domingo',
  'Segunda-feira',
  'Terça-feira',
  'Quarta-feira',
  'Quinta-feira',
  'Sexta-feira',
  'Sábado',
];

export const period = [
  'Manhã',
  'Tarde',
  'Noite',
];

export const address: AddressDetails = {
  cep: "",
  street: "",
  complement: "",
  number: "",
  district: "",
  city: "",
  state: "",
}