import React, { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import Title from '../../components/Title';
import { deleteCustomer, loadCustomers } from '../../reduxState/customer/customerSlice';
import { AppDispatch } from '../../reduxState/store';
import { useNavigate } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import DifferenceIcon from '@mui/icons-material/Difference';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

const CustomerList = ({ setShowAlert }: { setShowAlert: any }) => {
  const { customers, status } = useSelector((state: any) => state.customer);
  const { auth } = useSelector((state: any) => state.auth)
  
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [customerID, setCustomerID] = React.useState('');

  useEffect(() => {
    if (auth && customers && customers.length === 0 && status === null) {
      loadCustomers(auth.accessToken, auth.providerId, dispatch)
    }
  }, [customers, auth, dispatch, status])

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Deletar Cliente
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Tem certeza que deseja deletar este cliente?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} variant="contained">Cancelar</Button>
          <Button
            onClick={() => {
                deleteCustomer(customerID, auth.accessToken, dispatch)
                  .then((response) => {
                    setOpen(false);
                    setShowAlert({
                      status: 'success',
                      message: 'Cliente deletado com sucesso',
                      open: true
                    })
                  })
                  .catch(error => {
                    setOpen(false);
                    setShowAlert({
                      status: 'failed',
                      message: 'Error ao deletar liente',
                      open: true
                    })
                  })
                
              }
            }
            variant="outlined">
            Deletar
          </Button>
        </DialogActions>
      </Dialog>
      
      
      {customers && customers.length === 0 &&
        <Typography component="p" variant="h6" color="primary">
          Ainda não há clientes cadastrados.
        </Typography>
      }
      {customers && customers.length > 0 &&
      <>
        <Title>Clientes</Title>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Nome</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>CEP</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Tipo de cliente</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Valor</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Próximo vencimento</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Status do Pagamento</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Delete</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Detalhes</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customers.map((customer: any) => {
              return (
                <TableRow key={customer._id}>
                  <TableCell>{customer.name}</TableCell>
                  <TableCell>{customer.address.cep}</TableCell>
                  <TableCell>{customer.customerType}</TableCell>
                  <TableCell>{`R$ ${customer.price}`}</TableCell>
                  <TableCell>{customer.datePaymentExpired}</TableCell>
                  <TableCell>{customer.paymentStatus}</TableCell>
                  <TableCell>
                    <DeleteIcon
                      style={{ cursor: 'pointer', color: '#DB2700' }}
                      onClick={() => {
                        setOpen(true)
                        setCustomerID(customer._id)
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <DifferenceIcon
                      style={{ marginBottom: 5, cursor: 'pointer', color: '#3D7DE6' }}
                      onClick={() => navigate(`/clientes/detalhes/${customer._id}`, { state: customer })}
                    />
                  </TableCell>
                </TableRow>
              )
            }
            )}
          </TableBody>
        </Table>
      </>
      }
    </React.Fragment>
  );
}

export default CustomerList;
