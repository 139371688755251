import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ClientsList from './list';
import MainView from '../MainView';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import { Alerts } from '../../components/Alerts';

const Clientes = () => {
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState<any>({ status: undefined, message: "", open: false });

  return (
    <MainView>
      <Grid item xs={12}>
        {showAlert.open &&
          <Alerts
            displayAlert={setShowAlert}
            status={showAlert.status}
            message={showAlert.message}
          />
        }
        <Box
          display="flex"
          justifyContent="flex-end"
        >
          <Button
            color="inherit"
            variant="outlined"
            style={{ marginBottom: 10, marginTop: 10, cursor: 'pointer'}}
            onClick={() => navigate("/adicionar-clientes")}
          >
            Cadastrar Clientes
          </Button>
        </Box>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <ClientsList setShowAlert={setShowAlert} />
        </Paper>
      </Grid>
    </MainView>
  );
}

export default Clientes;
