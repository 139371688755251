import { PatternFormat } from "react-number-format";

const CPFInput = ({ value, customInput, onChange, onBlur }: { value?: any, customInput: any, onChange?: any, onBlur?: any }) => {
  return (
    <PatternFormat
      name="cpf"
      id="cpf"
      style={{ width: "100%" }}
      customInput={customInput}
      format="###.###.###-##"
      allowEmptyFormatting
      mask="_"
      value={value}
      required
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

export default CPFInput;
