import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { LineChart, axisClasses } from '@mui/x-charts';
import { ChartsTextStyle } from '@mui/x-charts/ChartsText';
import { handlePriceFormat } from '../utils/helpers';
import { Customer } from '../utils/types';

function createData(
  time: string,
  amount?: number,
): { time: string; amount: number | null } {
  return { time, amount: amount ?? null };
}

let dataInfoPago: any = [
  createData('08:00', 0),
];

let dataInfoPendente: any = [
  createData('08:00', 0),
];

export default function Chart(props: { status: string, customers: Customer[] }) {
  const theme = useTheme();
  
  React.useEffect(() => {
    props.customers
      .map((customer: Customer) => {
        if (customer.paymentStatus === 'pago') {
          return dataInfoPago.push(createData(customer.timeCustomerPriceSave!, handlePriceFormat(customer.price)))
        } else {
          return dataInfoPendente.push(createData(customer.timeCustomerPriceSave!, handlePriceFormat(customer.price)))
        }
      })
  }, [props.customers]);

  return (
    <React.Fragment>
      <div style={{ width: '100%', flexGrow: 1, overflow: 'hidden' }}>
        <LineChart
          dataset={props.status === 'pago' ? dataInfoPago : dataInfoPendente}
          margin={{
            top: 16,
            right: 20,
            left: 70,
            bottom: 30,
          }}
          xAxis={[
            {
              scaleType: 'point',
              dataKey: 'time',
              tickNumber: 2,
              tickLabelStyle: theme.typography.body2 as ChartsTextStyle,
            },
          ]}
          yAxis={[
            {
              label: 'Dados de Receita',
              labelStyle: {
                ...(theme.typography.body1 as ChartsTextStyle),
                fill: theme.palette.text.primary,
              },
              tickLabelStyle: theme.typography.body2 as ChartsTextStyle,
              tickNumber: 2,
            },
          ]}
          series={[
            {
              dataKey: 'amount',
              showMark: true,
              color: props.status === 'pago' ? theme.palette.primary.light : 'orange',
            },
          ]}
          sx={{
            [`.${axisClasses.root} line`]: { stroke: theme.palette.text.secondary },
            [`.${axisClasses.root} text`]: { fill: theme.palette.text.secondary },
            [`& .${axisClasses.left} .${axisClasses.label}`]: {
              transform: 'translateX(-25px)',
            },
          }}
        />
      </div>
    </React.Fragment>
  );
}