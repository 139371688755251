import { Typography, Container, Paper, Box, Button } from '@mui/material';
import { useNavigate } from "react-router-dom";
import MainView from '../MainView';

const Support = () => {
  const navigate = useNavigate();

  return (
    <MainView>
      <Container maxWidth="sm">
        <Paper elevation={3} style={{ display: 'block', padding: '20px', marginTop: '20px' }}>
          <Box>
            <Typography variant="h5" component="div" style={{ marginBottom: '20px' }}>
              Você precisa da nossa ajuda?
            </Typography>
            <Typography variant="body1" style={{ marginBottom: '20px' }}>
              Não precisa esperar, basta entrar em contato com o e-mail abaixo e responderemos o mais rápido possível. Agradecemos seu contato.
            </Typography>
            <Typography style={{color: '#1976d2'}} variant="body1" gutterBottom>
              E-mail: plataformapiscineiros@gmail.com
            </Typography>
          </Box>
        </Paper>
        <Box
          display="flex"
          justifyContent="flex-end"
        >
          <Button
            color="inherit"
            style={{ marginTop: 10, cursor: 'pointer', color: '#1976d2'}}
            onClick={() => navigate('/clientes')}
            variant="outlined"
            >
            Voltar para clientes
          </Button>
        </Box>
      </Container>
    </MainView>
  );
};

export default Support;
