import Grid from "@mui/material/Grid";
import { Container, Paper, Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import MainView from "../MainView";
import Chart from "../../components/Chart";
import Deposits from "../../components/Deposits";
import { getCurrentDay, sumPrices } from '../../utils/helpers';

const Finances = () => {
  const navigate = useNavigate();
  const { customers } = useSelector((state: any) => state.customer);
  
  return (
    <MainView>
      <Container>
        <Grid container spacing={2} style={{ marginTop: '5px' }}>
          {/* Recent Deposits */}
          <Grid item xs={12} md={4} lg={3}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: 240,
              }}
            >
              <Deposits title='Receita Gerada' dateInfo={getCurrentDay()} sum={sumPrices(customers, 'pago')} />
            </Paper>
          </Grid>
          {/* Chart */}
          <Grid item xs={12} md={8} lg={9}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: 240,
              }}
            >
              <Chart customers={customers} status='pago' />
            </Paper>
          </Grid>
           {/* Recent Deposits */}
           <Grid item xs={12} md={4} lg={3}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: 240,
              }}
            >
              <Deposits status='pendente' title='Receita ainda Pendente' dateInfo={getCurrentDay()} sum={sumPrices(customers, 'pendente')} />
            </Paper>
          </Grid>
          {/* Chart */}
          <Grid item xs={12} md={8} lg={9}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: 240,
              }}
            >
              <Chart customers={customers} status='pendente' />
            </Paper>
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="flex-end">
          <Button
            color="inherit"
            style={{ marginTop: 10, cursor: "pointer", color: "#1976d2" }}
            onClick={() => navigate("/clientes")}
            variant="outlined"
          >
            Voltar para clientes
          </Button>
        </Box>
      </Container>
    </MainView>
  );
};

export default Finances;
