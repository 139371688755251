import { useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import PrivateRoute from 'routes/privateRouters';
import { useSelector, useDispatch } from 'react-redux';
import { loadProdivers, resetProvider } from './reduxState/provider/providerSlice';
import { AppDispatch } from './reduxState/store';
import { logout } from './reduxState/auth/authSlice';
import { resetCustomer } from './reduxState/customer/customerSlice';
import { Navigate } from "react-router-dom";
import AddPProvider from "./views/Providers/add";
import Clientes from './views/Customers';
import AddNewCustomer from './views/Customers/add';
import CustomerDetails from './views/Customers/details';
import CustomerEdit from './views/Customers/edit';
import Profile from './views/Profile';
import Suporte from './views/Support';
import Financeiro from './views/Financeiro';
import PageNotFound from './views/NotFound';
import Login from "./views/Login";

const App = () => {
  const { auth } = useSelector((state: any) => state.auth);
  const isAuth = auth && auth.accessToken && auth.accessToken !== '';
  const dispatch = useDispatch<AppDispatch>();
  
  if (auth && (Date.now() >= auth.exp * 1000)) {
    dispatch(logout())
    dispatch(resetCustomer())
    dispatch(resetProvider())
    window.location.href = "/login";
  }
  
  useEffect(() => {
    if (auth) {
      loadProdivers(auth.accessToken, auth.providerId, dispatch);
    }
  }, [auth, dispatch])
  
  const router = createBrowserRouter([
    // PUBLIC ROUTES
    { path: "/login", element: <Login /> },
    { path: "/piscineiro/adicionar", element: <AddPProvider /> },
    { path: "*", element: <Navigate to="/login" replace /> },

    // PRIVATE ROUTES
    { path: "/", element: <PrivateRoute isAuth={isAuth} Component={Clientes} /> },
    { path: "/clientes", element: <PrivateRoute isAuth={isAuth} Component={Clientes} /> },
    { path: "/clientes/detalhes/:id", element: <PrivateRoute isAuth={isAuth} Component={CustomerDetails} /> },
    { path: "/clientes/editar/:id", element: <PrivateRoute isAuth={isAuth} Component={CustomerEdit} /> },
    { path: "/adicionar-clientes", element: <PrivateRoute isAuth={isAuth} Component={AddNewCustomer} /> },
    { path: "/perfil", element: <PrivateRoute isAuth={isAuth} Component={Profile} /> },
    { path: "/suporte", element: <PrivateRoute isAuth={isAuth} Component={Suporte} /> },
    { path: "/financeiro", element: <PrivateRoute isAuth={isAuth} Component={Financeiro} /> },
    { path: "*", element: <PrivateRoute isAuth={isAuth} Component={PageNotFound} /> },
  ]);
  
  return <RouterProvider router={router} />;
}

export default App;
