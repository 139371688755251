import { Customer } from './types';

export const searchCEP = (cep: string) => {
  return fetch(`https://viacep.com.br/ws/${cep}/json/`)
    .then((data) => data.json())
    .catch((error) => {
      throw new Error("Erro ao buscar CEP ::", error);
    });
};

export const convertNormalDate = (date?: any): number => {
  const dateExploded = date ? date.split("/") : "";
  const convertedDate = `${dateExploded[1]}/${dateExploded[0]}/${dateExploded[2]}`;
  return new Date(convertedDate).getTime();
};

export const convertToLibraryDate = (date: string): string => {
  const dateSplitted = date.split("/");
  return `${dateSplitted[2]}-${dateSplitted[1]}-${dateSplitted[0]}`;
};

export const handleCPF = (cpf?: any): string => {
  return cpf && cpf.replaceAll("-", "").replaceAll(".", "").replaceAll("_", "");
};

export const handlePhone = (phone?: any): string => {
  return phone && phone.replaceAll("(", "").replaceAll(")", "").replaceAll("_", "").replaceAll("-", "").replaceAll(" ", "");
};

export const handleCEP = (cep: string, setCustomAddress: any) => {
  if (cep.length === 8) {
    searchCEP(cep).then((customAddress) => {
      setCustomAddress({
        cep: customAddress.cep,
        street: customAddress.logradouro,
        complement: customAddress.complemento,
        district: customAddress.bairro,
        city: customAddress.localidade,
        state: customAddress.uf,
      });
    });
  }
};

export const disableDaysBefore = (date: any) => date < Date.now();

export const handlePriceFormat = (price: string): number => {
  return parseFloat(price.replace('R$', '').replace(' ', '').replace(',', '.'));
}

export const getCurrentDay = (): string => {
  const date = new Date();
  return `${date.getUTCDate() > 9 ? date.getUTCDate() : `0${date.getUTCDate()}`}/${date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`}/${date.getFullYear()}`;
}

export const sumPrices = (customer: Customer[], status: string): string => {
  let result = 0;
  for(let i = 0; i < customer.length; i++) {
    if (customer[i].paymentStatus === status) { 
      result += handlePriceFormat(customer[i].price);
    }
  }
  return result.toFixed(2);
}

export const setDateCustomerCreated = (): string => {
  const currentHours = new Date().getHours() >= 10 ? new Date().getHours() : `0${new Date().getHours()}`;
  const currentMinutes = new Date().getMinutes() >= 10 ? new Date().getMinutes() : `0${new Date().getMinutes()}`;

  return `${currentHours}:${currentMinutes}`;
}

export const validateCPF = (cpf: string): boolean => {
  let realCPF = handleCPF(cpf);
  let soma: number = 0;
  let result;
  
  if(realCPF == '00000000000') return false;
  for(let i=1; i<=9; i++) soma = soma + parseInt(realCPF.substring(i-1, i)) * (11 - i);
  result = (soma * 10) % 11;

  if((result == 10) || (result == 11)) result = 0;
  if(result != parseInt(realCPF.substring(9, 10))) return false;

  soma = 0;
  for(let i = 1; i <= 10; i++) soma = soma + parseInt(realCPF.substring(i-1, i))*(12-i);
  result = (soma * 10) % 11;

  if((result == 10) || (result == 11)) result = 0;
  if(result != parseInt(realCPF.substring(10, 11))) return false;
  return true;
}

export const validateEmail = (email: string) => {
  if (!email.match(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)) {
    return false
  }
  return true
}

export const validateCNPJ = (cnpj: string) => {
  return cnpj.match(/\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/i);
}
