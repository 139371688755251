import { PatternFormat } from "react-number-format";

const PhoneInput = ({ value, customInput, onChange, onBlur }: { value?: any, customInput: any, onChange?: any, onBlur?: any }) => {
  return (
    <PatternFormat
      name="phone"
      id="phone"
      style={{ width: "100%" }}
      customInput={customInput}
      format="(##) 9####-####"
      allowEmptyFormatting
      mask="_"
      value={value}
      required
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

export default PhoneInput;
