import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import MainView from '../MainView';
import { searchCEP, disableDaysBefore, convertNormalDate, handleCPF, setDateCustomerCreated, /*validateCPF, validateCNPJ,*/ validateEmail } from '../../utils/helpers';
import { addCustomer } from '../../reduxState/customer/customerSlice';
import { Customer, AddressDetails } from '../../utils/types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { AppDispatch } from '../../reduxState/store';
import { Alerts } from '../../components/Alerts';
import LoadingButton from '@mui/lab/LoadingButton';
import { Stack, Button } from "@mui/material";
import CurrencyInput from '../../components/CurrencyInput';
import CPFInput from '../../components/CPFInput';
import PhoneInput from '../../components/PhoneInput';
import Alert from "@mui/material/Alert";
import CNPJInput from "../../components/CNPJInput";
import { days, period, address } from "../../utils/commons";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddNewCustomer = () => {
  const [serviceDays, setServiceDays] = React.useState<string[]>([]);
  const [periodOfTime, setPeriodOfTime] = React.useState<string[]>([]);
  const [payment, setPayment] = React.useState<string>('');
  const [serviceType, setServiceType] = React.useState<string[]>([]);
  const [client, setVisitClient] = React.useState<string>('');
  const [paymentOft, setPaymentOft] = React.useState<string>('');
  const [paymentStatus, setPaymentStatus] = React.useState<string>('');
  const [myAddress, setMyAddress] = React.useState<AddressDetails>(address);
  const [showAlert, setShowAlert] = React.useState<any>({ status: undefined, message: "", open: false });
  const [isCEPWrong, setIsCEPWrong] = React.useState<boolean>(false);
  // const [isValidCPF, setIsValidCPF] = React.useState<boolean>(false);
  // const [isValidCNPJ, setIsValidCNPJ] = React.useState<boolean>(false);
  const [isValidEmail, setIsValidEmail] = React.useState<boolean>(false);
  const [myname, setMyName] = React.useState<string>('');
  const [mycpf, setMyCPF] = React.useState<string>('');
  const [mycnpj, setMyCNPJ] = React.useState<string>('');
  const [myemail, setMyEmail] = React.useState<string>('');
  const [myphone, setMyPhone] = React.useState<string>('');
  const [myprice, setMyPrice] = React.useState<string>('');
  const [mygender, setMyGender] = React.useState<string>('');
  const [customerDOC, setCustomerDOC] = React.useState<string>('CPF');

  const dispatch = useDispatch<AppDispatch>();
  // const { status } = useSelector((state: any) => state.customer)
  const { auth } = useSelector((state: any) => state.auth)
  const navigate = useNavigate();
  
  const handleCEP = (e: any) => {
    let cep = e.target.value;
    
    if (cep.length === 8) {
      searchCEP(cep)
        .then(customAddress => {
          if (customAddress.erro) {
            setIsCEPWrong(true);
          } else {
            setIsCEPWrong(false);
            setMyAddress({
              cep: customAddress.cep,
              street: customAddress.logradouro,
              complement: customAddress.complemento,
              number: customAddress.number,
              district: customAddress.bairro,
              city: customAddress.localidade,
              state: customAddress.uf,
            })
          }
        });
    }
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    
    const formData = new FormData(event.currentTarget);
    const payload: Customer = {
      name: formData.get('name') as string,
      cpf: handleCPF(formData.get('cpf')),
      cnpj: mycnpj || null,
      email: formData.get('email') as string,
      phone: formData.get('phone') as string, 
      address: {
        cep: myAddress.cep,
        street: myAddress.street,
        city: myAddress.city,
        complement: formData.get('complement') as string,
        number: formData.get('number') as string,
        state: myAddress.state,
        district: myAddress.district,
      },
      gender: formData.get('gender') as string,
      datePaymentExpired: formData.get('expired') as string,
      paymentMethod: payment,
      price: formData.get('price') as string,
      services: serviceType,
      customerType: client,
      paymentOften: paymentOft,
      paymentStatus,
      serviceDays,
      periodOfTime,
      realDatePaymentExpired: convertNormalDate(formData.get('expired')),
      dateToCreateCustomer: Date.now(),
      timeCustomerPriceSave: setDateCustomerCreated(),
      providerId: auth && auth.providerId
    };

    await dispatch(addCustomer(payload)).then((response) => {
      if (response.meta.requestStatus === 'fulfilled') {
        (document as any).getElementById("myform").reset();
        setMyAddress(address);
        setServiceDays([])
        setPeriodOfTime([])
        setPayment('')
        setServiceType([])
        setVisitClient('')
        setPaymentOft('')
        setPaymentStatus('')
      }
      
      if (response.type === "customer/addCustomer/fulfilled") {
        setShowAlert({
          status: 'success', 
          message: 'Cliente cadastrado com sucesso',
          open: true
        });    
      } else {
        setShowAlert({
          status: 'error',
          message: 'Error ao cadastrar o cliente',
          open: true
        });
      }
    });
  };

  return (
    <MainView>
      {showAlert.open &&
        <Alerts
          style={{ width: '100%' }}
          displayAlert={setShowAlert}
          status={showAlert.status}
          message={showAlert.message}
        />
      }
      <Box
        id="mainBox"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Cadastrar Cliente
        </Typography>        
          <Box id="myform" component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="name"
                  required
                  fullWidth
                  id="name"
                  label="Nome"
                  autoFocus
                  onChange={(e) => setMyName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  onChange={(e) => setMyEmail(e.target.value)}
                  onBlur={(e) => setIsValidEmail(!validateEmail(e.target.value))}
                />
                {isValidEmail &&
                  <Alert style={{ marginTop: '10px' }} variant="outlined" severity="error">
                    O E-MAIL digitado é inválido.
                  </Alert>
                }
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel id="documento-cliente">Documento do Cliente</InputLabel>
                <Select
                  fullWidth
                  labelId="demo-simple-select-label-customer"
                  id="demo-simple-select-customer"
                  label="Frequência de visitas"
                  value={customerDOC}
                  onChange={(e ) => setCustomerDOC(e.target.value)}
                >
                  <MenuItem value='CPF'>CPF</MenuItem>
                  <MenuItem value='CNPJ'>CNPJ</MenuItem>
                </Select>
              </Grid>
              {customerDOC === 'CNPJ' &&
                <Grid item xs={12} sm={4}>
                  <InputLabel id="documento-cliente">CNPJ</InputLabel>
                  <CNPJInput
                    customInput={TextField} 
                    // onBlur={(e: any) => setIsValidCNPJ(!validateCNPJ(e.target.value))}
                    onChange={(e: any) => setMyCNPJ(e.target.value)}
                  />
                  {/* {isValidCNPJ &&
                    <Alert style={{ marginTop: '10px' }} variant="outlined" severity="error">
                      O CNPJ é inválido.
                    </Alert>
                  } */}
                </Grid>
              }
              {customerDOC === 'CPF' &&
                <Grid item xs={12} sm={4}>
                  <InputLabel id="meu-cpf">CPF*</InputLabel>
                  <CPFInput
                    customInput={TextField}
                    // onBlur={(e: any) => setIsValidCPF(!validateCPF(e.target.value))}
                    onChange={(e: any) => setMyCPF(e.target.value)}
                  />
                  {/* {isValidCPF &&
                    <Alert style={{ marginTop: '10px' }} variant="outlined" severity="error">
                      O CPF digitado é inválido.
                    </Alert>
                  } */}
                </Grid>
              }
              <Grid item xs={12} sm={4}>
                <InputLabel id="meu-celular">Celular*</InputLabel>
                <PhoneInput customInput={TextField} onChange={(e: any) => setMyPhone(e.target.value)} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  required
                  fullWidth
                  name="cep"
                  label="CEP"
                  id="cep"
                  autoComplete="cep"
                  onChange={handleCEP}
                  onInput = {(e: any) =>{
                    e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,8)
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  required
                  fullWidth
                  name="street"
                  label="Rua, Avenida..."
                  id="street"
                  autoComplete="street"
                  disabled
                  value={myAddress.street}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  required
                  fullWidth
                  name="city"
                  label="Cidade"
                  id="city"
                  autoComplete="city"
                  disabled
                  value={myAddress.city}
                />
              </Grid>
              {isCEPWrong &&
                <Alert style={{ marginLeft: '15px', marginTop: '10px', width: '100%' }} variant="outlined" severity="error">
                  O CEP digitado pode estar incorreto.
                </Alert>
              }
              <Grid item xs={12} sm={2}>
                <TextField
                  required
                  fullWidth
                  name="number"
                  label="Número"
                  id="number"
                  autoComplete="number"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  name="complement"
                  label="Complemento"
                  id="complement"
                  autoComplete="complement"
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  required
                  fullWidth
                  name="state"
                  label="UF"
                  id="state"
                  autoComplete="state"
                  disabled
                  value={myAddress.state}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  required
                  fullWidth
                  name="district"
                  label="Bairo"
                  id="district"
                  autoComplete="district"
                  disabled
                  value={myAddress.district}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    slotProps={{ textField: { fullWidth: true } }}
                    name="expired"
                    className='calendar'
                    label="Data de vencimento"
                    shouldDisableDate={disableDaysBefore}
                  />
                </LocalizationProvider>
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <CurrencyInput customInput={TextField} onChange={(e: any) => setMyPrice(e.target.value)} />
              </Grid>
              <Grid item xs={12} sm={4}>
              <InputLabel id="metodo-de-pagamento">Método de Pagamento</InputLabel>
                <Select
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Método de pagamento"
                  value={payment}
                  onChange={(e) => setPayment(e.target.value)}
                >
                  <MenuItem value='credit card'>Cartão de Crédito</MenuItem>
                  <MenuItem value='pix'>Pix</MenuItem>
                  <MenuItem value='boleto'>Boleto</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel id="tipo-de-servico">Tipo de serviço</InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={serviceType}
                    onChange={(e) => setServiceType(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value)}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                    fullWidth
                  >
                    {auth.services.map((service: string) => (
                      <MenuItem key={service} value={service}>
                        <Checkbox checked={serviceType.indexOf(service) > -1} />
                        <ListItemText primary={service} />
                      </MenuItem>
                    ))}
                  </Select>
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel id="frequencia-de-visitas">Frequência de visitas</InputLabel>
                <Select
                  fullWidth
                  labelId="demo-simple-select-label-customer"
                  id="demo-simple-select-customer"
                  label="Frequência de visitas"
                  value={client}
                  onChange={(e ) => setVisitClient(e.target.value)}
                >
                  <MenuItem value='semanal'>Semanal</MenuItem>
                  <MenuItem value='quinzenal'>Quinzenal</MenuItem>
                  <MenuItem value='mensal'>Mensal</MenuItem>
                  <MenuItem value='livre'>Livre</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel id="frequencia-de-cobranca">Frequência de cobrança</InputLabel>
                <Select
                  fullWidth
                  labelId="demo-simple-select-label-customer"
                  id="demo-simple-select-customer"
                  label="Frequência de cobrança"
                  value={paymentOft}
                  onChange={(e ) => setPaymentOft(e.target.value)}
                >
                  <MenuItem value='semanal'>Semanal</MenuItem>
                  <MenuItem value='quinzenal'>Quinzenal</MenuItem>
                  <MenuItem value='mensal'>Mensal</MenuItem>
                  <MenuItem value='livre'>Livre</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel id="metodo-de-pagamento">Status de Pagamento</InputLabel>
                <Select
                  fullWidth
                  labelId="demo-simple-select-label-payment-status"
                  id="demo-simple-select-payment-status"
                  label="Status de pagamento"
                  value={paymentStatus}
                  onChange={(e ) => setPaymentStatus(e.target.value)}
                >
                  <MenuItem value='pago'>Pago</MenuItem>
                  <MenuItem value='pendente'>Pendente</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel id="metodo-de-pagamento">Dias dos serviços</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={serviceDays}
                  onChange={(e) => setServiceDays(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value)}
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                  fullWidth
                >
                  {days.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={serviceDays.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel id="periodo-de-atendimento">Periodo de atendimento</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={periodOfTime}
                  onChange={(e) => setPeriodOfTime(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value)}
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                  fullWidth
                >
                  {period.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={periodOfTime.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel id="genero">Gênero</InputLabel>
                <RadioGroup
                  row
                  name="genero"
                  onChange={(e) => setMyGender(e.target.value)}
                >
                  <FormControlLabel name='gender' value="female" control={<Radio />} label="Feminino" />
                  <FormControlLabel name='gender' value="male" control={<Radio />} label="Masculino" />
                </RadioGroup>
              </Grid>
            </Grid>
            <Stack style={{ marginTop: 30, marginBottom: 15 }} direction="row" spacing={2}>
              <Button
                fullWidth
                variant="outlined"
                onClick={() => navigate("/clientes")}
              >
                Cancelar
              </Button>
              <LoadingButton
                fullWidth
                sx={{ mt: 3, mb: 2 }}
                type='submit'
                variant="contained"
                disabled={/*(customerDOC === 'CNPJ' && isValidCNPJ) ||*/ isValidEmail || /*(customerDOC === 'CPF' && isValidCPF) ||*/ myname === '' || mygender === '' || /*(customerDOC === 'CNPJ' && mycnpj === '') || /*(customerDOC === 'CPF' && handleCPF(mycpf) === '') ||*/ myemail === '' || myphone === '' || myprice === '' || payment === '' || client === '' || paymentOft === '' || paymentStatus === '' || serviceDays.length === 0 || periodOfTime.length === 0}
              >
                <span>Cadastrar Cliente</span>
              </LoadingButton>
            </Stack>
          </Box>
      </Box>
    </MainView>
  );
}

export default AddNewCustomer;
