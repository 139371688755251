export const setEndpoint = () => {
    if (process.env.NODE_ENV === "development") {
        return {
            endpoint_base: 'http://localhost:9000',
            // email_api_key: '3fbc9809bf129621664d3c3fbc27a81a-f68a26c9-dfd8905c4', rm ultimo
        }
    }

    return {
        endpoint_base: 'https://piscineiros.com/api',
        // email_api_key: '3fbc9809bf129621664d3c3fbc27a81a-f68a26c9-dfd8905c4', rm ultimo
    }
}