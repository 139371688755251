import Alert from "@mui/material/Alert";

export interface AlertProps {
  status: "error" | "info" | "success" | "warning" | undefined;
  message: string;
  displayAlert: any;
  style?: any;
}

export const Alerts = (props: AlertProps) => {
  const { displayAlert, status, message, style } = props;

  return (
    <Alert
      onClose={() =>
        displayAlert({ status: undefined, message: "", open: false })
      }
      className="my-alert"
      variant="filled"
      severity={status}
      style={style}
    >
      {message}
    </Alert>
  );
};
